import { Box, Text, Button } from '@chakra-ui/react';
import Link from 'next/link';
import {useEffect} from "react";
import {useRouter} from "next/router";

const NotFoundPage = () => {
  const router = useRouter();

  useEffect(() => {
    router.replace('/');
  }, [router]);

  return (
    <Box w="100%" h="100vh">
      <Box
        mx="auto"
        mt="40%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Text textStyle="h1" p="24px">
          Oops!
        </Text>
        <Text textStyle="h5">Sorry there is nothing here.</Text>
        <Text textStyle="h5">
          Click the button below to return to our main site.
        </Text>
        <Link href="/" passHref legacyBehavior>
          <Button mt="24px">Click me</Button>
        </Link>
      </Box>
    </Box>
  );
};

export default NotFoundPage;
